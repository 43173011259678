import React from 'react';
import LogoImage from '../../../assets/images/logo.svg';

const Decoration = ({ className = '', showLogo = false } = {}) => {
  return (
    <div
      data-test="decoration"
      className={`flex flex-col items-center space-y-16 z-10 ${className}`}
    >
      <div className="flex items-center justify-center w-full">
        <div className="inline-block w-24 h-12 bg-primary"></div>
        <div className="inline-block w-24 h-12 mx-24 bg-primary"></div>
        <div className="inline-block w-24 h-12 bg-primary"></div>
      </div>
      {showLogo && <LogoImage data-test="logo" className="w-40 h-auto " />}
    </div>
  );
};

export { Decoration };
