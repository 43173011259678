import React, { useMemo } from 'react';

import {
  PaymentMethods,
  BannerWarning,
  ServiceCardButton,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../../assets/images/logo.svg';
import DineInImage from '../../../assets/images/dinein.svg';
import TakeawayImage from '../../../assets/images/takeaway.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultPrimaryBackground from '../../../assets/images/BG.png';

const PrimaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  healthMsg = true,
  languages = [],
  className = '',
  isCurrentLang,
  primaryAction,
  secondaryAction,
  onChangeLanguage = () => {},
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const isBordered = !!(icons?.length || card);
  const homeBannerBottomMargin =
    accessibilityEnabled && showTermsAndConditionsButton && healthMsg ? 'mb-2' : 'mb-20';

  const buttonsClass = useMemo(() => {
    return isCurrentLang('fr-ca') ? 'text-5xl' : 'text-6xl';
  }, [isCurrentLang]);

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultPrimaryBackground,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden ${className}`}
      >
        <div
          data-test="home-upper"
          className={`flex flex-col items-center justify-start w-full h-full ${homeBannerBottomMargin}`}
        >
          <PaymentMethods
            t={t}
            card={card}
            cash={cash}
            Logo={Logo}
            icons={icons}
            enableLogo={!accessibilityEnabled}
            className={`px-12 py-4 text-7xl ${isBordered ? 'border-2 border-black' : ''} ${
              accessibilityEnabled ? 'mt-0' : 'mt-16'
            } rounded `}
          />

          <div className="flex flex-col items-center justify-center mt-8 leading-none">
            <p className="font-extrabold text-black text-12xl" data-test="home-title">
              {t('homeTitle')}
            </p>

            <p className="font-extrabold text-black text-9xl" data-test="home-subtitle">
              {t('homeSubtitle')}
            </p>
          </div>
        </div>

        <div
          data-test="home-lower"
          className="flex flex-col-reverse items-center justify-start w-full h-full"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              className="w-4/5 mb-2"
              data-test="banner-terms"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              className="w-4/5 mb-2"
              data-test="banner-health"
              image={WashHandsImage}
            />
          )}

          <div
            data-test="home-services"
            className="flex items-stretch justify-center mb-24 space-x-16 w-4/5"
          >
            <ServiceCardButton
              data-test="button-secondary"
              className="bg-white shadow-lg w-home-button h-home-button rounded-home-button pt-0 pb-6"
              onClick={secondaryAction}
            >
              <div className={`mt-4 ${buttonsClass}`}>{t('homeSecondaryAction')}</div>
              <DineInImage className="w-48 h-48" />
            </ServiceCardButton>

            <ServiceCardButton
              data-test="button-primary"
              className="bg-white shadow-lg w-home-button h-home-button rounded-home-button pt-0 pb-6"
              onClick={primaryAction}
            >
              <div className={`mt-4 ${buttonsClass}`}>{t('homePrimaryAction')}</div>
              <TakeawayImage className="w-48 h-48" />
            </ServiceCardButton>
          </div>
        </div>

        <div className="flex justify-center w-full mt-8 mb-8" data-test="home-footer">
          <LocaleSelection showDivider languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
